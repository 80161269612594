import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'Step',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step')
    }
  },
  {
    path: '/1',
    name: 'Step1',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step1')
    }
  },
  {
    path: '/2',
    name: 'Step2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step2')
    }
  },
  {
    path: '/3',
    name: 'Step3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step3')
    }
  },
  {
    path: '/4',
    name: 'Step4',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step4')
    }
  },
  {
    path: '/5',
    name: 'Step5',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step5')
    }
  },
  {
    path: '/6',
    name: 'Step6',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step6')
    }
  },
  {
    path: '/7',
    name: 'Step7',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step7')
    }
  },
  {
    path: '/8',
    name: 'Step8',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Step8')
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "privacy-policy" */ '@/components/PrivacyPolicy.vue')
    }
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/modal/Login')
    }
  },
  {
    path: '/signup',
    name: 'Signup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/modal/Signup')
    }
  },
  {
    path: '/forgot-pin',
    name: 'ForgotPin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "forgot-pin" */ '@/components/ForgotPin')
    }
  },
  {
    path: '/authz/:authz',
    name: 'Authz',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "index" */ '@/components/Index')
    }
  },


  {
    path: '/setup-profile',
    name: 'SetupProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "setup-profile" */ '@/components/SetupProfile')
    }
  },
  {
    path: '/corporate-setup-profile/:step',
    name: 'CorporateSetup',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/components/CorporateSetup')
    }
  },
  {
    path: '/declaration-forms/',
    name: 'CheckboxForms',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "declaration-forms" */ '@/components/CheckboxForms')
    }
  },
 
  
  {
    path: '/individual-setup-profile/:step',
    name: 'Individual',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "individual-setup-profile" */ '@/components/Individual')
    }
  },
  {
    path: '/corp-documents',
    name: 'CorpDocs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corp-docs" */ '@/components/CorpDocs')
    }
  },
  {
    path: '/upload-document',
    name: 'UploadDocument',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "upload-document" */ '@/components/UploadDocument')
    }
  },
  {
    path: '/individual-associates',
    name: 'BoardOfDirectors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "individual-associates" */ '@/components/BoardOfDirectors')
    }
  },
  {
    path: '/company-profile',
    name: 'CompanyProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "company-profile" */ '@/components/CompanyProfile')
    }
  },
  {
    path: '/declaration',
    name: 'Declaration',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "declaration" */ '@/components/Declaration')
    }
  },
  {
    path: '/kyc',
    name: 'Kyc',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "kyc" */ '@/components/Kyc')
    }
  },
  {
    path: '/verification',
    name: 'Verification',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "verification" */ '@/components/Verification')
    }
  },
  

  {
    path: '/application',
    name: 'Application',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "application" */ '@/components/Application')
    }
  },
  {
    path: '/trade-history',
    name: 'TradeHistory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "trade-history" */ '@/components/TradeHistory')
    }
  },
  {
    path: '/fund-history',
    name: 'FundHistory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "trade-history" */ '@/components/FundHistory')
    }
  },
  {
    path: '/aml-report',
    name: 'AmlReporting',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "aml-report" */ '@/components/AmlReporting')
    }
  },
  {
    path: "/confirmemail/:utoken",
    name: 'EmailActivation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/views/EmailActivation')
    }
  },
  {
    path: "/confirmmphone/:utoken",
    name: 'MobileActivation',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function () {
      return import(/* webpackChunkName: "corporate-setup-profile" */ '@/views/MobileActivation')
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
