import { createStore } from 'vuex'

export default createStore({
  state: {
    init:{
      balance: {},
      currency:'PHP',
      walletAddress:'',
      amount: '',
      txnControlId:'',
      history: {},
      fundHistory: [],
      list:{
        documentData:[]       
      },
      tokens:[]
    },
    show: {
      isLoading: false,
      hasSession: false,
      showChangePin: false,
      showUnverify: false,
      login: false,
      signup: false,
      logout: false,
      guidelines: false,
      profile: {},
      corpDetails:{
        corpInfo:{},
        corpOfficeAddress:{},
        tradingAddress:{},
      },
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
