const common = {
  methods: {
    isBlank: (string) => {
      return string == '' || string == undefined || string== null;
    },
    validateEmail: (email) => {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    isNumber: (evt) => {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAmount: function(evt) {
      evt = (evt) ? evt : window.event;
      let charCode = (evt.which) ? evt.which : evt.keyCode;

      if ((charCode > 31 && charCode != 46  && (charCode < 48 || charCode > 57)) ) {
      evt.preventDefault();
      } else {
      return true;
      }
    },
    formatAmount: (num, decimalPlaces) => {
      return Number(parseFloat(num).toFixed(decimalPlaces)).toLocaleString('en', {
          minimumFractionDigits: decimalPlaces
      });
    },
  }
};

export default {common}