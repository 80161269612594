let baseCxApiUrl = '/cx'

let rest = {
  login: baseCxApiUrl + '/login/pin',
  logout: baseCxApiUrl + '/logout',
  sessionCheck: baseCxApiUrl + '/sessioncheck',
  profile: baseCxApiUrl + '/profile',
  markets: '/pub/store/markets',
  funds: baseCxApiUrl + '/funds',
}
export default {rest}
